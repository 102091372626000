body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

.logo {
  background: url("https://hsto.org/getpro/habr/company/00b/f9c/27a/00bf9c27a7e0ab9d98f5f973a18d62fd.png");
  background-repeat: no-repeat !important;
  background-size: 40px, 40px !important;
  width: 40px;
  height: 40px;
  margin: 17px 0 16px 40px;
  float: left;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle thead > tr > th,
.ant-table.ant-table-middle tbody > tr > td {
  padding: 1px 8px !important;
}

.ant-list-sm .ant-list-item {
  padding: 2px !important;
}
